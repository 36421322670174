import React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from 'components/common/Buttons';
import { colors, mediaQueries } from 'variables';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const Title = styled.h2`
  margin: 42px 0 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 122px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  margin-top: 124px;

  ${mediaQueries.lg} {
    margin-top: 92px;
  }
`;

const InfoParagraph = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 22.68px;
`;

const HomepageButton = styled(PrimaryButton)`
  width: 100%;
  margin: 56px 0 181px;

  ${mediaQueries.sm} {
    width: auto;
  }
`;

const EmailLink = styled.a`
  text-decoration: none;
  color: ${colors.primary};
`;

export const SuccessPage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Title>Thank you for joining the awards!</Title>

      <Info>
        <InfoParagraph>
          Thank you very much for the registration for the Design Educates Awards 2022! We are excited to
          see you in the group of participants and will be looking forward to seeing your designs that
          educate!
        </InfoParagraph>
        <InfoParagraph>
          Meanwhile, if you have any questions, please do not hesitate to contact us at
          <EmailLink href="mailto: hello@designeducates.com">&nbsp;hello@designeducates.com</EmailLink>
          <br />
          Please check the main website of the Awards to learn more how to submit your designs.
        </InfoParagraph>
        <InfoParagraph>
          With best wishes,
          <br />
          DEA Team
        </InfoParagraph>
      </Info>

      <a href="https://designeducates.com">
        <HomepageButton>Back to homepage</HomepageButton>
      </a>
    </Wrapper>
  );
};
