import React from 'react';
import styled from 'styled-components';

import logo from 'assets/icons/logo.svg';

const Wrapper = styled.header`
  padding: 20px 14px;
  position: sticky;
  top: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 5;
`;

const Logo = styled.img`
  width: 174px;
  height: 61px;
`;

export const Header: React.FC = () => (
  <Wrapper>
    <a href="https://designeducates.com">
      <Logo src={logo} alt="Link to DEA main page" />
    </a>
  </Wrapper>
);
