import * as Yup from 'yup';
import { ParticipantCategory, ProjectCategory } from '../typings/registerForm';

export const initialValues = {
  name: '',
  email: '',
  company: '',
  country: '',
  category: '',
  projects: ['project1'],
  project1: [],
};

export const initialValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  email: Yup.string().email('Invalid email').required('Required field'),
  category: Yup.string().required('Required field'),
});

export const projectValidationSchema = (): Yup.AnySchema =>
  Yup.mixed().test('isSelected', 'Please select at least one option', value => {
    if (value === null) {
      return true;
    }

    if (!Array.isArray(value)) {
      return false;
    }

    return value.length > 0;
  });

export const categoryOptions = [
  {
    label: 'Individual student',
    value: ParticipantCategory.IndividualStudent,
  },
  {
    label: 'Team of Students',
    value: ParticipantCategory.TeamOfStudents,
  },
  {
    label: 'Individual professional',
    value: ParticipantCategory.IndividualProfessional,
  },
  {
    label: 'Company > 10 employees',
    value: ParticipantCategory.CompanyGreaterThan10Employees,
  },
  {
    label: 'Company 11 - 100 employees',
    value: ParticipantCategory.CompanyGreater11To100Employees,
  },
  {
    label: 'Company 100+ employees',
    value: ParticipantCategory.CompanyGreaterThan100Employees,
  },
];

export const projectCategoryCheckboxes = [
  {
    label: 'Architectural design',
    value: ProjectCategory.ArchitecturalDesign,
  },
  {
    label: 'Product design',
    value: ProjectCategory.ProductDesign,
  },
  {
    label: 'Universal design',
    value: ProjectCategory.UniversalDesign,
  },
  {
    label: 'Responsive design',
    value: ProjectCategory.ResponsiveDesign,
  },
];

export const formatPrice = (price: number): string => (Math.round(price * 100) / 100).toFixed(2);

export const getEntryPrice = (category: string): number => {
  switch (category) {
    case ParticipantCategory.IndividualStudent:
      return 50;
    case ParticipantCategory.TeamOfStudents:
      return 75;
    case ParticipantCategory.IndividualProfessional:
      return 150;
    case ParticipantCategory.CompanyGreaterThan10Employees:
      return 175;
    case ParticipantCategory.CompanyGreater11To100Employees:
      return 250;
    default:
      return 400;
  }
};

export const getDiscountedPrice = (price: number, entries: number): number => {
  switch (entries) {
    case 1:
      return price;
    case 2:
      return price * 0.85;
    case 3:
      return price * 0.8;
    case 4:
      return price * 0.75;
    case 5:
      return price * 0.65;
    default:
      return price * 0.5;
  }
};
