import React from 'react';
import styled from 'styled-components';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { Input } from 'components/form/Input';
import { Select } from 'components/form/Select';
import { categoryOptions } from 'helpers/registerForm';
import { mediaQueries } from 'variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const Title = styled.h3`
  margin: 0 0 32px;
`;

interface Values {
  name: string;
  email: string;
  company: string;
  country: string;
  category: string;
}

interface Props {
  values: FormikValues;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
}

export const StepOne: React.FC<Props> = ({ values, handleChange, errors, touched }) => (
  <Wrapper>
    <Title>Contact details</Title>

    <Input
      label="Full Name"
      name="name"
      value={values.name}
      onChange={handleChange}
      error={errors.name}
      touched={touched.name}
    />

    <Input
      type="email"
      label="Email"
      name="email"
      value={values.email}
      onChange={handleChange}
      error={errors.email}
      touched={touched.email}
    />

    <Input label="Company (optional)" name="company" value={values.company} onChange={handleChange} />

    <Input label="Country (optional)" name="country" value={values.country} onChange={handleChange} />

    <Select
      label="Participant category"
      name="category"
      value={values.category}
      onChange={handleChange}
      options={categoryOptions}
      initialOption="Select category"
      error={errors.category}
      touched={touched.category}
    />
  </Wrapper>
);
