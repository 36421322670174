const mediaQ = (size: number) => `@media only screen and (min-width: ${size}px)`;

export const colors = {
  background: '#202020',
  primary: '#FFFFFF',
  secondary: '#8B8B8B',
  error: '#DB3832',
};

export const mediaQueries = {
  xs: mediaQ(320),
  sm: mediaQ(576),
  md: mediaQ(850),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
};

export const projectRegex = /project\d+$/;

export const apiBase = process.env.REACT_APP_API_URL;

export const apiUrls = {
  step1Create: `${apiBase}form/profile/step-1/`,
  step2Create: `${apiBase}profiles/registration-process/`,
  profileCreate: `${apiBase}profiles/`,
};
