import React from 'react';
import styled from 'styled-components';
import { FormikErrors, FormikTouched } from 'formik';

import { PrimaryButton } from 'components/common/Buttons';
import { CheckboxGroup } from 'components/form/CheckboxGroup';
import { projectCategoryCheckboxes } from 'helpers/registerForm';
import { mediaQueries } from 'variables';

import crossIcon from 'assets/icons/cross.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const Project = styled.div`
  margin-bottom: 64px;

  ${mediaQueries.lg} {
    margin-bottom: 56px;
  }
`;

const NewProjectButton = styled(PrimaryButton)`
  ${mediaQueries.lg} {
    width: 256px;
  }
`;

const ProjectTitle = styled.h3`
  display: flex;
  align-items: center;
  margin: 0 0 32px;
`;

const DeleteIcon = styled.img`
  margin-left: 16px;
  width: 20px;
  height: 20px;
`;

interface Values {
  name: string;
  email: string;
  company: string;
  country: string;
  category: string;
  projects: string[];
  [key: string]: unknown;
}

interface Props {
  handleChange: (e: React.ChangeEvent<unknown>) => void;
  values: Values;
  setFieldValue: (field: string, value: unknown) => void;
  appendToValidationSchema: (name: string) => void;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}

export const StepTwo: React.FC<Props> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  appendToValidationSchema,
}) => {
  const removeProject = (projectId: number): void => {
    const filteredProjects = values.projects
      .filter(project => project !== `project${projectId}`)
      .map((project, index) => `project${index + 1}`);

    values.projects.forEach((project, index) => {
      if (index + 1 < projectId) {
        return;
      }

      if (index + 1 === values.projects.length) {
        setFieldValue(`project${index + 1}`, null);
        return;
      }

      setFieldValue(`project${index + 1}`, values[`project${index + 2}`]);
    });

    setFieldValue('projects', filteredProjects);
  };

  return (
    <Wrapper>
      {values.projects.map((project, index) => (
        <Project key={project}>
          <ProjectTitle>
            Project {(index + 1).toString().padStart(2, '0')} - select categories
            {values.projects.length > 1 && (
              <DeleteIcon src={crossIcon} onClick={() => removeProject(index + 1)} />
            )}
          </ProjectTitle>
          <CheckboxGroup
            checkboxes={projectCategoryCheckboxes}
            name={`project${index + 1}`}
            onChange={handleChange}
            error={errors[`project${index + 1}`]}
            touched={touched[`project${index + 1}`]}
          />
        </Project>
      ))}

      <NewProjectButton
        type="button"
        onClick={() => {
          const newProjectName = `project${values.projects.length + 1}`;

          appendToValidationSchema(newProjectName);
          setFieldValue(newProjectName, '');
          setFieldValue('projects', [...values.projects, newProjectName]);
        }}
      >
        Add next project
      </NewProjectButton>
    </Wrapper>
  );
};
