import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import { Header } from 'components/common/Header';
import { Footer } from 'components/common/Footer';
import { RegisterForm } from 'pages/RegisterForm';
import { SuccessPage } from 'pages/SuccessPage';
import { colors } from 'variables';

import AssociateMonoLightWoff from './assets/fonts/AssociateMono-Light.woff';
import AssociateMonoLightWoff2 from './assets/fonts/AssociateMono-Light.woff2';
import AssociateMonoMediumWoff from './assets/fonts/AssociateMono-Medium.woff';
import AssociateMonoMediumWoff2 from './assets/fonts/AssociateMono-Medium.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Associate Mono';
    src: url(${AssociateMonoLightWoff}) format('woff'), url(${AssociateMonoLightWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Associate Mono Bold';
    src: url(${AssociateMonoMediumWoff}) format('woff'), url(${AssociateMonoMediumWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  html {
    background: ${colors.background};
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20.16px;
    font-family: Associate Mono, sans-serif;
    background: ${colors.background};
    color: ${colors.primary};
  }

  main {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  h2 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 30.24px;
  }

  h3 {
    font-family: Associate Mono Bold, sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 25.2px;
  }
`;

export const App: React.FC = () => (
  <BrowserRouter>
    <GlobalStyle />
    <Header />

    <Switch>
      <Route path="/register" exact>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.REACT_APP_PAYPAL_ID,
            currency: 'USD',
            'disable-funding': 'card',
          }}
        >
          <RegisterForm />
        </PayPalScriptProvider>
      </Route>

      <Route path="/success">
        <SuccessPage />
      </Route>

      <Redirect to="/register" />
    </Switch>

    <Footer />
  </BrowserRouter>
);
