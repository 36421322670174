import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { PayPalButtons } from '@paypal/react-paypal-js';

import {
  formatPrice,
  getDiscountedPrice,
  getEntryPrice,
  initialValidationSchema,
  initialValues,
  projectValidationSchema,
} from 'helpers/registerForm';
import { CreateOrderActions, OnApproveActions, OnClickActions } from 'typings/payPal';
import { apiUrls, colors, mediaQueries, projectRegex } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { PayPalInfoModal } from 'components/form/PayPalInfoModal';
import { StepBlock } from 'components/register/StepBlock';
import { StepOne } from './registerForm/StepOne';
import { StepTwo } from './registerForm/StepTwo';
import { StepThree } from './registerForm/StepThree';

const Wrapper = styled.main`
  padding: 0 24px;
  width: 100%;

  ${mediaQueries.lg} {
    padding: 0 88px;
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -12px;

  ${mediaQueries.lg} {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
`;

const Title = styled.h2`
  margin: 59px 0 16px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 64px;
  }
`;

const SubTitle = styled.p`
  margin: 16px 0 64px;
  text-align: center;

  ${mediaQueries.lg} {
    margin-bottom: 96px;
  }
`;

const StepBlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 96px;

  ${mediaQueries.lg} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column-reverse;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`;

const FeeAndNextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  ${mediaQueries.lg} {
    width: 390px;
  }
`;

const Fee = styled.h2`
  margin: 0 0 16px;
`;

const FeeParagraph = styled.p`
  margin: 0 0 12px;

  :nth-of-type(2) {
    margin-bottom: 42px;
  }
`;

const NextButton = styled(PrimaryButton)`
  margin-bottom: 64px;

  ${mediaQueries.lg} {
    width: 256px;
  }
`;

const PayPalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  align-self: center;

  ${mediaQueries.lg} {
    align-self: flex-start;
    margin: initial;
    width: 271px;
  }
`;

const PayPalMessage = styled.p`
  font-size: 12px;
  line-height: 15px;
`;

const ErrorMessage = styled(PayPalMessage)`
  margin: -60px 0 60px;
  color: ${colors.error};

  ${mediaQueries.lg} {
    margin: initial;
  }
`;

const PayPalDisclaimer = styled.p`
  margin-bottom: 83px;

  ${mediaQueries.lg} {
    margin-bottom: 100px;
  }
`;

const CardButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #2c2e2e;
  border: none;
  color: ${colors.primary};
  margin-bottom: 48px;
  cursor: pointer;

  ${mediaQueries.lg} {
    width: 271px;
  }
`;

const Link = styled.a`
  color: ${colors.primary};
`;

export const RegisterForm: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [allowedSteps, setAllowedSteps] = React.useState<{ [key: string]: boolean }>({
    '1': true,
    '2': false,
    '3': false,
  });
  const [validationSchema, setValidationSchema] = React.useState(initialValidationSchema);
  const [fee, setFee] = React.useState({ final: '0.00', regular: '0.00', discount: '0.00' });
  const [apiError, setApiError] = React.useState('');
  const [isPaymentMessageVisible, setIsPaymentMessageVisible] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  const history = useHistory();

  const stepBlocks = [
    { step: 1, name: 'Contact details' },
    { step: 2, name: 'Projects & Categories' },
    { step: 3, name: 'Summary & Payment' },
  ];

  return (
    <Wrapper>
      <FormHeader>
        <div>
          <Title>Register for the Edition 2022!</Title>
          <SubTitle>
            You can already register and receive an
            <br />
            additional discount of 15% (from Early Registration)
          </SubTitle>
        </div>

        <StepBlocks>
          {stepBlocks.map(({ step, name }) => (
            <StepBlock
              key={step}
              step={step}
              name={name}
              isActive={step === currentStep}
              onClick={() => {
                if (allowedSteps[step]) {
                  setCurrentStep(step);
                }
              }}
            />
          ))}
        </StepBlocks>
      </FormHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={() => undefined}
      >
        {({
          errors,
          handleChange,
          touched,
          values,
          setFieldValue,
          validateForm,
          setErrors,
          setTouched,
        }) => {
          React.useEffect(() => {
            if (!values.category) {
              return;
            }

            const projectEntries = Object.entries(values)
              .filter(([key, value]) => key.match(projectRegex) && value !== null)
              .reduce((acc: string[], curr) => {
                const current = curr as [string, string[]];

                return [...acc, ...current[1]];
              }, []);

            const price = projectEntries.length * getEntryPrice(values.category);
            const discountedPrice = getDiscountedPrice(price, projectEntries.length) * 0.85;
            setFee({
              final: formatPrice(discountedPrice),
              regular: formatPrice(price),
              discount: formatPrice(price - discountedPrice),
            });
          }, [values]);

          const getFormValues = () => {
            const projects = Object.entries(values)
              .filter(([key, _value]) => key.match(projectRegex))
              .map(value => value[1])
              .filter(project => project !== null);

            return {
              fullName: values.name,
              email: values.email,
              participantCategory: values.category,
              projects,
              ...(values.company && { company: values.company }),
              ...(values.country && { country: values.country }),
              total: `$${fee.final}`,
              discount: `- $${fee.discount}`,
              regular: `$${fee.regular}`,
            };
          };

          const step1validation = () => {
            setIsFetching(true);
            const step1Values = { name: true, email: true, category: true };
            //prevent cache
            validateForm()
              .then(setErrors)
              .then(() => setTouched(step1Values))
              .then(() => {
                if (Object.keys(errors).length !== 0) {
                  throw new Error('');
                }
              })
              .then(() =>
                fetch(apiUrls.step1Create, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: values.email,
                    full_name: values.name,
                    participant_category: values.category,
                    ...(values.company && { company: values.company }),
                    ...(values.country && { country: values.country }),
                  }),
                }),
              )
              .then(res => {
                setApiError('');

                if (!res.ok) {
                  throw new Error('Something went wrong');
                }
              })
              .then(() => {
                setAllowedSteps(prevState => ({ ...prevState, '2': true }));
                setCurrentStep(2);
                setValidationSchema(
                  validationSchema.concat(
                    Yup.object({
                      project1: projectValidationSchema(),
                    }),
                  ),
                );
              })
              .catch(err => setApiError(err.message))
              .finally(() => setIsFetching(false));
          };

          const step2validation = () => {
            const step2Values = Object.keys(values)
              .filter(value => value.match(projectRegex))
              .reduce((acc, curr) => ({ ...acc, [curr]: true }), {});

            setIsFetching(true);

            validateForm()
              .then(formErrors => {
                setErrors(formErrors);
                setTouched(step2Values);

                return formErrors;
              })
              .then(formErrors => {
                if (Object.keys(formErrors).length !== 0) {
                  throw new Error('');
                }
              })
              .then(() =>
                fetch(apiUrls.step2Create, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(getFormValues()),
                }),
              )
              .then(res => {
                setApiError('');

                if (!res.ok) {
                  throw new Error('Something went wrong');
                }
              })
              .then(() => {
                setAllowedSteps(prevState => ({ ...prevState, '3': true }));
                setCurrentStep(3);
              })
              .catch(err => setApiError(err.message))
              .finally(() => setIsFetching(false));
          };

          return (
            <StyledForm>
              {currentStep === 1 && (
                <StepOne values={values} handleChange={handleChange} errors={errors} touched={touched} />
              )}
              {currentStep === 2 && (
                <StepTwo
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  appendToValidationSchema={(name: string) =>
                    setValidationSchema(
                      validationSchema.concat(
                        Yup.object({
                          [name]: projectValidationSchema(),
                        }),
                      ),
                    )
                  }
                />
              )}

              {currentStep === 3 && <StepThree values={values} />}

              <FeeAndNextSection>
                <Fee>Registration fee: ${fee.final}</Fee>
                <FeeParagraph>Your discount: -${fee.discount}</FeeParagraph>
                <FeeParagraph>Regular fee: ${fee.regular}</FeeParagraph>
                {currentStep === 3 ? (
                  <>
                    <PayPalWrapper>
                      <PayPalButtons
                        style={{ color: 'white', height: 40 }}
                        createOrder={(data: unknown, actions: CreateOrderActions) =>
                          actions.order.create({
                            purchase_units: [{ amount: { value: fee.final } }],
                            application_context: {
                              shipping_preference: 'NO_SHIPPING',
                            },
                          })
                        }
                        onClick={(data: unknown, actions: OnClickActions) =>
                          validateForm()
                            .then(setErrors)
                            .then(() => {
                              setApiError('');

                              if (Object.keys(errors).length !== 0) {
                                setApiError('Please fill all required fields and try again');
                                return actions.reject();
                              }

                              return actions.resolve();
                            })
                        }
                        onApprove={(data: unknown, actions: OnApproveActions) => {
                          setIsPaymentMessageVisible(true);
                          const formValues = getFormValues();

                          return actions.order.capture().then(details =>
                            fetch(apiUrls.profileCreate, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({ ...formValues, paypalOrderId: details.id }),
                            })
                              .then(res => {
                                setApiError('');

                                if (!res.ok) {
                                  throw new Error(res.statusText);
                                }
                              })
                              .then(() => history.push('/success'))
                              .catch(() => {
                                setIsPaymentMessageVisible(false);
                                setApiError('Something went wrong');
                              }),
                          );
                        }}
                        onError={() => setApiError('Something went wrong')}
                      />
                    </PayPalWrapper>

                    <p style={{ marginTop: '36px', marginBottom: '0' }}>Credit or debit card</p>
                    <p>
                      If you would like to pay with a bank transfer, we will contact you shortly with the
                      details for the payment
                    </p>

                    <CardButton
                      disabled={isFetching}
                      onClick={() => {
                        const formValues = getFormValues();

                        fetch(apiUrls.profileCreate, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ ...formValues, paypalOrderId: 0 }),
                        })
                          .then(res => {
                            setApiError('');

                            if (!res.ok) {
                              throw new Error(res.statusText);
                            }
                          })
                          .then(() => history.push('/success'))
                          .catch(() => {
                            setIsPaymentMessageVisible(false);
                            setApiError('Something went wrong');
                          });
                      }}
                    >
                      Bank transfer
                    </CardButton>

                    <PayPalDisclaimer>
                      By submitting the payment you confirm you have read and agree with{' '}
                      <Link
                        href="https://designeducates.com/guidelines/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Guidelines & Privacy Policy
                      </Link>
                    </PayPalDisclaimer>
                    <PayPalInfoModal isOpen={isPaymentMessageVisible} />
                  </>
                ) : (
                  <NextButton
                    type="button"
                    onClick={() => (currentStep === 1 ? step1validation() : step2validation())}
                    disabled={isFetching}
                  >
                    Save & next step
                  </NextButton>
                )}
                {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
              </FeeAndNextSection>
            </StyledForm>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
