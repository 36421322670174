import styled from 'styled-components';

import { colors } from 'variables';

const Button = styled.button`
  border: 3px solid ${colors.primary};
  font-family: Associate Mono Bold, sans-serif;
  font-size: 18px;
  line-height: 22.68px;
  font-weight: normal;
  padding: 18px 32px;
  cursor: pointer;
`;

export const PrimaryButton = styled(Button)`
  color: ${colors.primary};
  background: transparent;
  transition: background-color 0.1s ease;

  :hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${colors.primary};
  color: ${colors.background};
`;
