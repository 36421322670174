import React from 'react';
import styled from 'styled-components';

import { categoryOptions, getEntryPrice, projectCategoryCheckboxes } from 'helpers/registerForm';
import { mediaQueries, projectRegex } from 'variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaQueries.lg} {
    width: 640px;
    margin-right: 99px;
  }
`;

const DetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  ${mediaQueries.lg} {
    margin-bottom: 64px;
  }
`;

const Title = styled.h3`
  margin: 0;
`;

const Detail = styled.p`
  display: flex;
  flex-flow: row wrap;
  margin: 16px 0 0;

  :first-of-type {
    margin-top: 24px;
  }
`;

const Label = styled.u`
  margin-bottom: 8px;
`;

const ProjectWrapper = styled.div`
  margin-top: 24px;

  :first-of-type {
    margin-top: 30px;
  }
`;

const ProjectLabel = styled.label`
  text-decoration: underline;
`;

const ProjectCategory = styled.p`
  margin: 8px 0 0 0;

  :first-of-type {
    margin: 16px 0 0 0;
  }
`;

interface Values {
  name: string;
  email: string;
  company: string;
  country: string;
  category: string;
  projects: string[];
  [key: string]: unknown;
}

interface Project {
  name: string;
  categories: string[];
}

interface Props {
  values: Values;
}

export const StepThree: React.FC<Props> = ({ values }) => {
  const contactDetails = [
    { label: 'Full name:', detail: values.name },
    { label: 'Email:', detail: values.email },
    { label: 'Company:', detail: values.company },
    { label: 'Country:', detail: values.country },
    {
      label: 'Participant category:',
      detail: categoryOptions.find(({ value }) => values.category === value)?.label,
    },
  ];

  const projects = Object.entries(values)
    .filter(([key, value]) => key.match(projectRegex) && value !== null)
    .reduce((acc: Project[], curr, index) => {
      const current = curr as [string, string[]];

      return [
        ...acc,
        {
          name: `Project ${(index + 1).toString().padStart(2, '0')}`,
          categories: current[1].map(
            category => projectCategoryCheckboxes.find(({ value }) => value === category)?.label || '',
          ),
        },
      ];
    }, []);

  return (
    <Wrapper>
      <DetailsBlock>
        <Title>Contact details</Title>
        {contactDetails.map(
          ({ label, detail }) =>
            detail && (
              <Detail key={label}>
                <Label>{label}</Label>&nbsp;<span>{detail}</span>
              </Detail>
            ),
        )}
      </DetailsBlock>

      <DetailsBlock>
        <Title>Project(s) to submit</Title>
        {projects.map(project => (
          <ProjectWrapper key={project.name}>
            <ProjectLabel>
              {project.name} - ${getEntryPrice(values.category) * project.categories.length}
            </ProjectLabel>
            {project.categories.map(category => (
              <ProjectCategory key={category}>{category}</ProjectCategory>
            ))}
          </ProjectWrapper>
        ))}
      </DetailsBlock>
    </Wrapper>
  );
};
