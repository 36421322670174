import React from 'react';
import styled from 'styled-components';

import { colors } from 'variables';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const InputField = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primary};
  box-sizing: border-box;
  background: transparent;
  color: ${colors.primary};
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

interface Props {
  type?: string;
  label: string;
  name: string;
  error?: string;
  touched?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<unknown>) => void;
}

export const Input: React.FC<Props> = ({ error, label, name, onChange, touched, value, type }) => (
  <Wrapper>
    <Label htmlFor={name}>{label}</Label>
    <InputField id={name} type={type} name={name} value={value} onChange={onChange} />
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);
